import { ThemeService, lightTheme } from '@volosoft/ngx-lepton-x';
import { BreadcrumbComponent } from './custom-components/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './custom-components/breadcrumb/breadcrumb.service';
import { delay, filter } from 'rxjs';
import { eThemeLeptonXComponents, eUserMenuItems } from '@volosoft/abp.ng.theme.lepton-x';
import { NavigationEnd, Router } from '@angular/router';
import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';
import {
  AuthService,
  ConfigStateService,
  ReplaceableComponentsService,
  RoutesService,
} from '@abp/ng.core';
import { NavItemsService, UserMenuService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <p-toast position="bottom-left"></p-toast>
    <abp-dynamic-layout></abp-dynamic-layout>
    <abp-gdpr-cookie-consent></abp-gdpr-cookie-consent>
  `,
  standalone: false,
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private configStateService: ConfigStateService,
    private themeService: ThemeService,

    private _rc: ReplaceableComponentsService,
    private _userMenu: UserMenuService,
    private _router: Router,
    private _routes: RoutesService,
    private _breadcrumbService: BreadcrumbService,
  ) {
    themeService.setTheme(lightTheme);

    _rc.add({
      key: eThemeLeptonXComponents.Settings,
      component: undefined,
    });

    _rc.add({
      key: eThemeLeptonXComponents.Breadcrumb,
      component: BreadcrumbComponent,
    });
  }

  async ngOnInit() {
    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.setBreadcrumbs(event as NavigationEnd);
    });

    this.configStateService.getOne$('currentUser').subscribe(user => {
      if (user.isAuthenticated) {
        //this._router.navigate(['/']);
        // Sentry.setContext('userInfo', user);
      } else {
        this._router.navigate(['/message']);
      }
    });

    let tries = 0;

    let done = false;
    while (tries < 50 && !done) {
      await new Promise(f => setTimeout(f, 50));
      const scrollContainerElement = document.getElementsByClassName('lpx-scroll-container');
      if (scrollContainerElement.length > 0) {
        scrollContainerElement[0].classList.remove('lpx-scroll-container');
        done = true;
        break;
      }
    }
  }

  ngAfterViewInit(): void {
    // gets rid of scrolling breaking on dropdown lists
    const thing = document.getElementsByClassName('lpx-scroll-container');
    if (thing.length > 0) {
      thing[0]?.classList.remove('lpx-scroll-container');
    }
  }

  private setBreadcrumbs(event: NavigationEnd) {
    switch ((event as NavigationEnd).url) {
      case '/identity/organization-units':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Organization Units', link: '/identity/organization-units', id: 2 },
        ]);
        break;
      case '/identity/roles':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Roles', link: '/identity/roles', id: 2 },
        ]);
        break;
      case '/identity/users':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Users', link: '/identity/users', id: 2 },
        ]);
        break;
      case '/identity/claim-types':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Claim Types', link: '/identity/claim-types', id: 2 },
        ]);
        break;
      case '/identity/security-logs':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Security Logs', link: '/identity/security-logs', id: 2 },
        ]);
        break;
      case '/openiddict/Applications':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'OpenId Applications', link: '/openiddict/applications', id: 2 },
        ]);
        break;
      case '/openiddict/Scopes':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'OpenId Scopes', link: '/openiddict/scopes', id: 2 },
        ]);
        break;
      case '/language-management/languages':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Languages', link: '/language-management/languages', id: 2 },
        ]);
        break;
      case '/language-management/texts':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Language Texts', link: '/language-management/texts', id: 2 },
        ]);
        break;
      case '/text-template-management/text-templates':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Text Templates', link: '/text-template-management/text-templates', id: 2 },
        ]);
        break;
      case '/audit-logs':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Audit Logs', link: '/audit-logging/audit-logs', id: 2 },
        ]);
        break;
      case '/setting-management':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Settings', link: '/setting-management', id: 2 },
        ]);
        break;
      case '/gdpr':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Personal Data', link: '/gdpr', id: 2 },
        ]);
        break;
      case '/account/manage':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Account', link: '/account/manage', id: 2 },
        ]);
        break;
      case '/account/security-logs':
        this._breadcrumbService.setBreadcrumbs([
          { icon: 'pi pi-home', link: '/', id: 1 },
          { title: 'Security Logs', link: '/account/security-logs', id: 2 },
        ]);
        break;
    }
  }
}
